import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsFilterComponent } from '../analytics-filter/analytics-filter.component';
import { SchoolService } from 'src/app/services/api/school.service';
import { ANALYTICS_TABS, SELECTED_SCHOOL_GROUP_KEY, SELF_ID_KEY } from 'src/app/Constants/Constant';
import { AdminReportComponent } from '../admin-report/admin-report.component';
import { LabelService } from 'src/app/services/label.service';
import { SchoolSettingService } from 'src/app/services/school-setting.service';
import { SchoolGroupService } from 'src/app/services/school-group.service';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  public tabs = [ANALYTICS_TABS.OVERVIEW, ANALYTICS_TABS.SCHOOL, ANALYTICS_TABS.TEACHER, ANALYTICS_TABS.STUDENT, ANALYTICS_TABS.SUBJECT]
  private refTabs = [ANALYTICS_TABS.OVERVIEW, ANALYTICS_TABS.SCHOOL, ANALYTICS_TABS.TEACHER, ANALYTICS_TABS.STUDENT, ANALYTICS_TABS.SUBJECT]
  constructor(
    private analyticsSvc: AnalyticsService,
    public dialog: MatDialog,
    private schoolService: SchoolService,
    private labelSvc: LabelService,
    private schoolSettingService: SchoolSettingService,
    private schoolGroupSvc: SchoolGroupService,
  ) {
    this.labelSvc.labels$.subscribe(val => {
      if (val['school']) {
        this.tabs[1] = val['school']
      }
      if (val['teacher']) {
        this.tabs[2] = val['teacher']
      }
      if (val['student']) {
        this.tabs[3] = val['student']
      }
      if (val['subject']) {
        this.tabs[4] = val['subject']
      }
    })
  }

  @ViewChild('overview') overViewTab: ElementRef;
  @ViewChild('subject') subjectTab: ElementRef;
  @ViewChild('school') schoolTab: ElementRef;
  @ViewChild('student') studentTab: ElementRef;
  @ViewChild('teacher') teacherTab: ElementRef;

  private schoolTabFilters: any = null;
  private teacherTabFilters: any = null;
  private studentTabFilters: any = null;
  private subjectTabFilters: any = null;
  private allTabFilters: any[] = [null, this.schoolTabFilters, this.teacherTabFilters, this.studentTabFilters, this.subjectTabFilters];
  private schools = [];
  private sessions = [];
  public selectedTab: string = this.tabs[0];
  public selectedTabIndex: number = 0;

  ngOnInit(): void {
    let schoolGroup  = this.schoolGroupSvc.getSelectedSchoolGroup();
    this.analyticsSvc.getOverviewDashboard();
    this.analyticsSvc.getSubjectsDashboard();
    this.analyticsSvc.getSchoolDashboard();
    this.analyticsSvc.getStudentDashboard();
    this.analyticsSvc.getTeacherDashboard();

    this.schoolService.getSchools();
    this.schoolSettingService.getMasterSchoolSession(schoolGroup).subscribe((sessions) => {
      this.sessions = sessions;
    });

    this.schoolService.schooList$.subscribe((schools) => {
      this.schools = schools;
    })

    this.analyticsSvc.allTabFilters$.subscribe((allTabFilters) => {
      this.allTabFilters = allTabFilters;
    })
  }

  ngAfterViewInit() {
    this.analyticsSvc.overviewDashboard$.subscribe((data) => {
      if (data) {
        this.overViewTab.nativeElement.src = data.url + "#titled=true&bordered=false";
      }
    })
    this.analyticsSvc.subjectDashboard$.subscribe((data) => {
      if (data) {
        this.subjectTab.nativeElement.src = data.url + "#titled=true&bordered=false";
        if (data.filters) {
          // this.allTabFilters[4] = data.filters;
        }
      }
    })

    this.analyticsSvc.schoolDashboard$.subscribe((data) => {
      if (data) {
        this.schoolTab.nativeElement.src = data.url + "#titled=true&bordered=false";
        if (data.filters) {
          // this.allTabFilters[1] = data.filters;
        }
      }
    })

    this.analyticsSvc.studentDashboard$.subscribe((data) => {
      if (data) {
        this.studentTab.nativeElement.src = data.url + "#titled=true&bordered=false";
        if (data.filters) {
          // this.allTabFilters[3] = data.filters;
        }
      }
    })

    this.analyticsSvc.teacherDashboard$.subscribe((data) => {
      if (data) {
        this.teacherTab.nativeElement.src = data.url + "#titled=true&bordered=false";
        if (data.filters) {
          // this.allTabFilters[2] = data.filters;
        }
      }
    })
  }

  tabSelected(event) {
    this.selectedTab = this.tabs[event.index];
    this.selectedTabIndex = event.index;
  }


  openFilters() {
    let appliedFilters = null
    if (this.allTabFilters[this.selectedTabIndex]) {
      appliedFilters = {
        school: [...this.allTabFilters[this.selectedTabIndex].schools],
        session: [...this.allTabFilters[this.selectedTabIndex].session],
        startDate: this.allTabFilters[this.selectedTabIndex].startDate,
        endDate: this.allTabFilters[this.selectedTabIndex].endDate,
        allDateRangeValue: this.allTabFilters[this.selectedTabIndex].allDateRangeValue,
        rollingDateRangeValue: this.allTabFilters[this.selectedTabIndex].rollingDateRangeValue
      }
    }
    const dialogRef = this.dialog.open(AnalyticsFilterComponent, {
      width: '43%',
      maxWidth: '440px',
      height: '100%',
      data: {
        schools: this.schools.map(s => { return { ...s } }),
        sessions: this.sessions.map(s => { return { ...s } }),

        filterTab: this.refTabs[this.selectedTabIndex],
        appliedFilters: appliedFilters,
      },
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['admin-theme', 'analytics-filter-width-class'],
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(filters => {
      if (filters) {
        if (filters.session && filters.session == "all") {
          filters.session = this.sessions.map((session) => session.id);
        } else {
          filters.session = [filters.session]
        }
        if (filters.schools && filters.schools == "all") {
          filters.schools = this.schools.map((school) => school.id);
        } else {
          filters.schools = [filters.schools]
        }
        this.analyticsSvc.updateDashboard(this.refTabs[this.selectedTabIndex], filters);
      }
    })
  }

  openReport() {
    const dialogRef = this.dialog.open(AdminReportComponent, {
      width: '480px',
      minWidth: '480px',
      height: '100%',
      backdropClass: 'blur',
      disableClose: true,
      panelClass: ['admin-theme', 'analytics-filter-width-class'],
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(filters => {
      if (filters) {
        console.log(filters);
      }
    })
  }

  ngOnDestroy() {
    this.analyticsSvc.clearAllTabFilters();
  }
}
