import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';
import { SnackbarService } from './snackbar.service';
import { ANALYTICS_TABS } from '../Constants/Constant';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {



  constructor(
    private crud: CrudService,
    private snackbar: SnackbarService,
  ) {

  }

  private OVERVIEW_TAB_INDEX = 0;
  private SCHOOL_TAB_INDEX = 1;
  private TEACHER_TAB_INDEX = 2;
  private STUDENT_TAB_INDEX = 3;
  private SUBJECT_TAB_INDEX = 4;


  private allTabFilters$$: BehaviorSubject<any[]> = new BehaviorSubject([null, null, null, null, null]);

  public get allTabFilters$(): Observable<any[]> {
    return this.allTabFilters$$.asObservable();
  }

  private overviewDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get overviewDashboard$(): Observable<any> {
    return this.overviewDashboard$$.asObservable();
  }

  private schoolOverviewDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get schoolOverviewDashboard$(): Observable<any> {
    return this.schoolOverviewDashboard$$.asObservable();
  }


  private subjectDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get subjectDashboard$(): Observable<any> {
    return this.subjectDashboard$$.asObservable();
  }

  private schoolDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get schoolDashboard$(): Observable<any> {
    return this.schoolDashboard$$.asObservable();
  }

  private studentDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get studentDashboard$(): Observable<any> {
    return this.studentDashboard$$.asObservable();
  }

  private teacherDashboard$$: BehaviorSubject<any> = new BehaviorSubject(null);

  public get teacherDashboard$(): Observable<any> {
    return this.teacherDashboard$$.asObservable();
  }

  // for filters
  private sessionsList$$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  public get sessionsList$(): Observable<any[]> {
    return this.sessionsList$$.asObservable();
  }

  private updateAllTabFilters(index, filters) {
    const allFilters = this.allTabFilters$$.value;
    allFilters[index] = filters;
    this.allTabFilters$$.next(allFilters);
  }


  public getOverviewDashboard(filters?, requestBody?) {
    this.crud.post(environment.apiUrl + 'analytics/overview', {}).subscribe((resp) => {
      if (filters) {
        this.updateAllTabFilters(this.OVERVIEW_TAB_INDEX, filters)
        this.overviewDashboard$$.next({ url: resp.dashboardURL, filters: filters })
      } else {
        this.overviewDashboard$$.next({ url: resp.dashboardURL })
      }
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard')
    )
  }

  public getSchoolOverviewDashboard(schoolId: string, singleSchool?: string) {
    if(!singleSchool) {
      singleSchool = '';
    }
    let body = { schoolIDs: [schoolId] }
    this.crud.post(environment.apiUrl + `analytics/overview?single-school=${singleSchool}`, body).subscribe((resp) => {
      this.schoolOverviewDashboard$$.next({ url: resp.dashboardURL })

    }, err => this.snackbar.openSnackbar('unable to get school analytics dashboard')
    )
  }

  public getSubjectsDashboard(filters?, requestBody?) {
    let body = filters ? requestBody : {};
    const filtersApplied = filters && filters.schools  && (filters.schools.length === 1 || filters.session.length === 1)
    this.crud.post(environment.apiUrl + 'analytics/subject', {...body, filtersApplied }).subscribe((resp) => {
      if (filters) {
        this.updateAllTabFilters(this.SUBJECT_TAB_INDEX, filters)
        this.subjectDashboard$$.next({ url: resp.dashboardURL, filters: filters });
      } else {
        this.subjectDashboard$$.next({ url: resp.dashboardURL });
      }
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard')
    )
  }

  public getSchoolDashboard(filters?, requestBody?) {
    let body = filters ? requestBody : {};
    const filtersApplied = filters && filters.schools  && (filters.schools.length === 1 || filters.session.length === 1)
    console.log(filters, requestBody, 'filters and request body')
    this.crud.post(environment.apiUrl + 'analytics/school', {...body, filtersApplied }).subscribe((resp) => {
      if (filters) {
        this.updateAllTabFilters(this.SCHOOL_TAB_INDEX, filters)
        this.schoolDashboard$$.next({ url: resp.dashboardURL, filters: filters });
      } else {
        this.schoolDashboard$$.next({ url: resp.dashboardURL });
      }
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard')
    )
  }

  public getStudentDashboard(filters?, requestBody?) {
    let body = filters ? requestBody : {};
    const filtersApplied = filters && filters.schools  && (filters.schools.length === 1 || filters.session.length === 1)
    this.crud.post(environment.apiUrl + 'analytics/student', {...body, filtersApplied }).subscribe((resp) => {
      if (filters) {
        this.updateAllTabFilters(this.STUDENT_TAB_INDEX, filters)
        this.studentDashboard$$.next({ url: resp.dashboardURL, filters: filters });
      } else {
        this.studentDashboard$$.next({ url: resp.dashboardURL });
      }
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard')
    )
  }

  public getTeacherDashboard(filters?, requestBody?) {
    let body = filters ? requestBody : {};
    const filtersApplied = filters && filters.schools  && (filters.schools.length === 1 || filters.session.length === 1)
    this.crud.post(environment.apiUrl + 'analytics/teacher', {...body, filtersApplied }).subscribe((resp) => {
      if (filters) {
        this.updateAllTabFilters(this.TEACHER_TAB_INDEX, filters);
        this.teacherDashboard$$.next({ url: resp.dashboardURL, filters: filters })
      } else {
        this.teacherDashboard$$.next({ url: resp.dashboardURL });
      }
    }, err => this.snackbar.openSnackbar('Unable to get analytics dashboard')
    )
  }

  public updateDashboard(dashboard, filters) {
    let requestBody: any;
    if (filters) {
      if (filters.schools) {
        requestBody ? requestBody.schoolIDs = filters.schools : requestBody = { schoolIDs: filters.schools };
      }
      if (filters.session) {
        requestBody ? requestBody.sessionIDs = filters.session : requestBody = { sessionIDs: filters.session };
      }
      if (filters.startDate) {
        requestBody ? requestBody.startDate = filters.startDate : requestBody = { startDate: filters.session };
      }
      if (filters.endDate) {
        requestBody ? requestBody.endDate = filters.endDate : requestBody = { endDate: filters.endDate };
      }
      switch (dashboard) {
        case ANALYTICS_TABS.OVERVIEW:
          break;
        case ANALYTICS_TABS.SCHOOL:
          this.getSchoolDashboard(filters, requestBody);
          break;
        case ANALYTICS_TABS.TEACHER:
          this.getTeacherDashboard(filters, requestBody);
          break;
        case ANALYTICS_TABS.STUDENT:
          this.getStudentDashboard(filters, requestBody);
          break;
        case ANALYTICS_TABS.SUBJECT:
          this.getSubjectsDashboard(filters, requestBody);
          break;
      }
    }
  }

  clear() {
    this.sessionsList$$.next([]);
    this.clearAllTabFilters();
    this.clearSchoolOverviewDashboard();
  }

  clearAllTabFilters() {
    this.allTabFilters$$.next([null, null, null, null, null]);
  }

  clearSchoolOverviewDashboard() {
    this.schoolOverviewDashboard$$.next(null);
  }
}
