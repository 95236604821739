<ng-container *ngIf="!isOtp && !isPin">
    <div class="phone">
        <div class="text">Enter 10 digit mobile number </div>
        <div class="input">
            <input [ngClass]="hasError ? 'danger' : null" matInput type="number" placeholder="Phone Number" maxlengh=10 (keydown.enter)="isOtp ? verify() : login()" (input)="enterPhoneNumber($event.target.value)" [(ngModel)]="inputNumber" />
        </div>
        <div *ngIf="hasError || isNumberError" [ngStyle]="{'color': '#ff8156', 'margin-top': '16px'}" class="error">
            {{inputNumber ? 'It is an invalid mobile number' : 'Please enter the mobile number' }}
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isOtp">
    <div class="otp">
        <div class="text">Please enter the 6 digit otp sent to your mobile no.</div>
        <div class="input">
            <input [ngClass]="isOTPError ? 'danger' : null" matInput type="number" placeholder="Enter OTP" [(ngModel)]="otp" (ngModelChange)="onChange()" (keydown.enter)="isOtp ? verify() : login()" />
        </div>
        <div (click)="otpTimer === '00:00' && sendOtp()" class="resendOTP" [ngClass]="{'disabled': otpTimer !== '00:00', 'admin' : theme === 'admin', 'teacher': theme === 'teacher', 'student' : theme === 'student'}">
            <button mat-flat-button [disabled]="otpTimer !== '00:00'">Resend OTP? {{otpTimer}}</button>
        </div>
        <div *ngIf="isOTPError" [ngStyle]="{'color': '#ff8156'}" class="error">
            {{otpError}}
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isPin">
    <div class="otp">
        <div class="text">Please enter the 4 digit pin</div>
        <div class="input">
            <input [ngClass]="isPinError ? 'danger' : null" matInput type="number" placeholder="Enter PIN" [(ngModel)]="pin" (ngModelChange)="onPinChange()" (keydown.enter)="pinVerify()" />
        </div>
        <div *ngIf="isPinError" [ngStyle]="{'color': '#ff8156'}" class="error">
            {{pinError}}
        </div>
    </div>
</ng-container>
