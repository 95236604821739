import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { minDate, timeregex } from 'src/app/Constants/Constant';
import { DateTimeService } from 'src/app/services/date-time.service';
import { LabelService } from 'src/app/services/label.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ScheduleLiveClassService } from '../services/schedule-live-class.service';

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
@Component({
  selector: 'app-schedule-live-class',
  templateUrl: './schedule-live-class.component.html',
  styleUrls: ['./schedule-live-class.component.scss']
})
export class ScheduleLiveClassComponent implements OnInit, OnDestroy {
  liveForm: FormGroup;
  recurClassForm: FormGroup;
  minDate: Date;
  recurClassValue: boolean = false;
  recurClassLength: number = 0;
  days = DAYS;
  isLoading = true;
  loadingAuthStatus = true;
  classes = [];
  subscription = new Subscription();
  labelData;
  disableSubmission = false;
  gapiSetup = false;
  authInstance: gapi.auth2.GoogleAuth;
  isAuthenticated = false;
  authenticating = false;
  constructor(
    private fb: FormBuilder,
    public scheduleLiveClassSvc: ScheduleLiveClassService,
    public dateservice: DateTimeService,
    public snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<any>,
    private labelService: LabelService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.subscription = this.labelService.labels$.subscribe(data => this.labelData = data);
    this.dialogRef.updatePosition({ right: `0px` });
    if (this.data && this.data.classID) {
      this.isLoading = false;
    } else {
      this.scheduleLiveClassSvc.getClasses().subscribe(
        (res) => {
          this.classes = res.filter(res => res.status == 'active');
          this.isLoading = false;
        },
        (err) => {
          this.snackbar.openSnackbar('Unable to fetch classes list');
          this.dialogRef.close();
          this.subscription.unsubscribe();
        }
      )
    }

    this.scheduleLiveClassSvc.checkUserOAuthStatus().subscribe((res) => {
      this.isAuthenticated = res.isAuthenticated;
      this.loadingAuthStatus = false;
    }, err => this.snackbar.openSnackbar('unable to get user auth status: ' + err.error && err.error.error));

    this.minDate = minDate;
    this.buildForm();
  }

  get recurDayArray() {
    return this.recurClassForm ? this.recurClassForm.get('recurDaysArray') as FormArray : null;
  }

  buildForm() {
    this.liveForm = this.fb.group({
      title: [null, [Validators.required]],
      description: [null, [Validators.required]],
      class: [null],
      startDate: [null],
      startTime: [null, [Validators.pattern(timeregex)]],
      recurStartDate: [null],
      recurEndDate: [null],
      duration: [null, [Validators.pattern(timeregex)]],
    })
  }
  startTimeValidator() {
    return this.dateservice.concatDateTime(this.liveForm.controls['startDate'].value, this.liveForm.controls['startTime'].value) > new Date()
  }

  getTimeError(form, type) {
    return form.get(type).hasError('required') ? 'Time is required' :
      form.get(type).hasError('pattern') ? 'Not a valid time, please add time in the format (HH:MM)' : null
  }

  getDurationError(form, type) {
    return form.get(type).hasError('required') ? 'Duration is required' :
      form.get(type).hasError('pattern') ? 'Not a valid duration, please add time in the 24 hr format (HH:MM)' : null
  }

  close() {
    this.dialogRef.close();
    this.subscription.unsubscribe();
  }

  addEvent(event, datetype: 'start' | 'end') {
    datetype === 'start' ? this.liveForm.controls['startDate'].setValue(event.value) : this.liveForm.controls['endDate'].setValue(event.value)
  }

  addRecurDateTime(event, datetype: 'start' | 'end') {
    datetype === 'start' ? this.liveForm.controls['recurStartDate'].setValue(event.value) : this.liveForm.controls['recurEndDate'].setValue(event.value);
  }

  formatDate(event, index) {
    this.recurDayArray.value[index].startDate = event.value
  }

  checkRecurClass(event) {
    this.recurDayArray?.clear();
    this.recurClassValue = event.checked;
    if (event.checked) {
      this.recurClassForm = this.fb.group({
        recurDaysArray: this.fb.array([])
      })
      this.addDay();
    }
  }
  // checkForRecuringDayValidator() {
  //   return (c: AbstractControl): {[key: string]: any} => {
  //     if (c.value.length >= 'min')
  //       return null;

  //     return { MinLengthArray: true};
  //   }
  // }
  disableCheckForDay(day) {
    console.log(day, this.recurDayArray.value)
    for (let i = 0; i < this.recurDayArray.length; i++) {
      if (this.recurDayArray.at(i).value.day == day) {
        return true;
      }
    }
  }
  delete(index) {
    this.recurDayArray.removeAt(index);
    this.recurClassLength -= 1;
    this.recurClassValue = this.recurClassLength === 0 ? false : true;
  }

  addDay() {
    let fb = this.fb.group({
      day: ['', Validators.required],
      time: [null, [Validators.required, Validators.pattern(timeregex)]],
      duration: [null, [Validators.required, Validators.pattern(timeregex)]],
    })
    this.recurDayArray.push(fb);
    this.recurClassLength += 1;
  }

  fetchClassID(liveFormControl) {
    if (this.data && this.data.classID) {
      return [this.data.classID];
    } else {
      let classID = [];
      liveFormControl.class.map(list => classID.push(list.id));
      return classID;
    }
  }


  checkIsFormValid() {
    if (this.recurClassValue) {
      console.log(this.recurDayArray.valid)
      return this.liveForm.valid && this.liveForm.controls['recurStartDate'].value && this.liveForm.controls['recurEndDate'].value && this.recurDayArray.valid;
    } else {
      return ((this.liveForm.valid) && (this.data || this.liveForm.value.class));
    }
  }

  getNonRecurBody(liveFormControl) {
    liveFormControl.duration = this.dateservice.calculateInMins(this.liveForm.controls['duration'].value);
    liveFormControl.time = this.dateservice.concatDateTime(this.liveForm.controls['startDate'].value, this.liveForm.controls['startTime'].value);
    const requestbody = {
      title: liveFormControl.title,
      description: liveFormControl.description,
      startsAt: liveFormControl.time.toISOString(),
      duration: liveFormControl.duration,
      isRecurring: false
    }
    return requestbody;
  }

  getRecurBody(liveFormControl) {
    let recurSchedule = [];
    this.recurDayArray.value.map(recurDay => recurSchedule.push({
      day: this.days.indexOf(recurDay.day),
      startsAt: this.dateservice.concatDateTime(this.liveForm.controls['recurStartDate'].value, recurDay.time),
      duration: this.dateservice.calculateInMins(recurDay.duration)
    }));

    const requestbody = {
      title: liveFormControl.title,
      description: liveFormControl.description,
      recurringStart: liveFormControl.recurStartDate.toISOString(),
      recurringEnd: liveFormControl.recurEndDate.toISOString(),
      schedule: recurSchedule,
      isRecurring: true
    }
    return requestbody;
  }

  checkForDateValidation() {
    if (this.liveForm.value.recurringStart && this.liveForm.value.recurEndDate) {
      return this.liveForm.value.recurStartDate < this.liveForm.value.recurEndDate
    } else {
      return true;
    }
  }

  save() {
    if (!this.checkIsFormValid()) {
      return this.snackbar.openSnackbar('Please fill the necessary details!!');
    }
    if (!this.checkForDateValidation()) {
      return this.snackbar.openSnackbar('Start date cannot exceed end date!!')
    }
    if (!this.recurClassValue && !this.startTimeValidator()) {
      return this.snackbar.openSnackbar('Start date time cannot be before current time!!')
    }
    const liveFormControl = this.liveForm.value;
    const classID = this.fetchClassID(liveFormControl);
    const requestBody = this.recurClassValue ? this.getRecurBody(liveFormControl) : this.getNonRecurBody(liveFormControl);
    requestBody['classIDs'] = classID;
    this.disableSubmission = true;
    this.scheduleLiveClassSvc.createClass(requestBody).subscribe(
      (res) => {
        this.disableSubmission = false;
        this.snackbar.openSnackbar('live class is successfully created!!');
        this.dialogRef.close('done');
        this.subscription.unsubscribe();
      },
      (err) => {
        this.disableSubmission = false;
        this.snackbar.openSnackbar(err.error.error)
      }
    );
  }

  async initGoogleAuth(): Promise<void> {
    //  Create a new Promise where the resolve
    // function is the callback passed to gapi.load
    const pload = new Promise((resolve) => {
      gapi.load('auth2', resolve);
    });

    // When the first promise resolves, it means we have gapi
    // loaded and that we can call gapi.init
    return pload.then(async () => {
      await gapi.auth2
        .init({
          client_id: '716979846987-9krfgg0bkng7lscn27el0h27g1c8gra8.apps.googleusercontent.com',
          scope: 'https://www.googleapis.com/auth/calendar.events profile',
        })
        .then(auth => {
          this.gapiSetup = true;
          this.authInstance = auth;
        });
    });
  }

  async authenticate(): Promise<gapi.auth2.GoogleUser> {
    this.authenticating = true;
    // Initialize gapi if not done yet
    if (!this.gapiSetup) {
      await this.initGoogleAuth();
    }
    // Resolve or reject signin Promise
    return new Promise(async () => {
      await this.authInstance.grantOfflineAccess().then(
        resp => {
          this.scheduleLiveClassSvc.saveOAuthToken(resp.code).subscribe(() => {
            this.isAuthenticated = true;
            this.authenticating = false;
            this.cd.detectChanges();
          }, err => {
            this.snackbar.openSnackbar('unable to save oauth token: ' + err.error && err.error.error);
            this.authenticating = false;
          });
        },
        error => {
          console.log('error is: ', error);
          this.authenticating = false;
        });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
