import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveClassService {
  
  public liveClassRoute(studentId, classID?) {
    return environment.apiUrl + '/class/schedule/student/' + studentId + '?period=today&class-id=' + classID;
  }

  public joinRoute(classID) {
    return environment.socketServerBaseUrl + `/class/live/${classID}/join`;
  }

  constructor(public crud: CrudService) { }

  public liveClass(studentID, classID?) {
    return this.crud.get(this.liveClassRoute(studentID, classID));
  }

  public joinClass(classID) {
    return this.crud.get(this.joinRoute(classID))
  }
}
